
.menu-modal{
    height: 100%;
    width: 100%;
    background-color: #000;
    text-align: center;
    margin-top: 200%;
    ul{
        hr{
            width: 80%;
        border: none;
        height: 1px;
        margin-bottom: 5%;
    /* Set the hr color */
        color: white; /* old IE */
        background-color: white; /* Modern Browsers */
        }
        margin-top: -190%;
        text-align: center;
        padding: 0;
        background-color: #000;
    }
    h1{
        color: #FFFFFF;
        font-family: 'Dosis', sans-serif;
        font-size: 15pt;
        padding: 20px;
    }
    a{
        cursor: pointer;
        color: #FFFFFF;
        text-decoration: none;
    }
    

      
    //   a {
    //     box-shadow: inset 0 0 0 0 #54b3d6;
    //     color: #54b3d6;
    //     margin: 0 -.25rem;
    //     padding: 0 .25rem;
    //     transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    //   }
    //   a:hover {
    //     box-shadow: inset 100px 0 0 0 #54b3d6;
    //     color: white;
    //   }
}