.nav-bar{
    position: initial;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: transparent;
    display: flex;
    height: 132px;
    
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  .nav-bar-desktop{
    
    margin-left: 10%;
  }
     img{
      width: 39px;
      height: 39px;
     
    }
    ul {
        display: none;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        justify-content: space-between;
        
      }
      
      li {
        float: left;
        
      }
      
      li a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        margin: 0px 20px;
        position: relative;
        font-family: 'Open Sans', sans-serif;
        font-size: 13pt;
        margin-right: 50px;
        
        
      }
      a:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #fff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      a:hover:after { 
        width: 100%; 
        left: 0; 
      }
      
    @media (min-width: 768px) {
        
        ul{
            display: contents;
        }
        .hamburguer-logo{
            display: none;
        }
    }
    
    @media (min-width: 1150px) {
        img{
          margin-left: 200%;
        }
    }
    @media (min-width: 1440px) {
        
    }
    @media (max-width: 1144px){
      .nav-bar-desktop{
        margin-left: 4%;
        display: inline-flex;
      }
    }
    @media (max-width: 1144px){
      .nav-bar-desktop{
        margin-left: 4%;
      }
    }
    @media (min-width: 1200px){
      .nav-bar li a{
        margin: 0 60px;
      }
    }
    @media (min-width: 1400px){
      .nav-bar-desktop{
        display: inline-flex;
      }
      .nav-bar-desktop {
        li a{
          margin: 0 70px;
        }
        
      }
    }
    
}
@media (min-width: 850px) {
        
  .nav-bar{
    justify-content: center;
  }
}