#app { height: 100% }
html,
body {
  scroll-behavior: smooth;
  height: 100%;
}



.swiper {
  width: 100%;
  height: 100%;
}
.swiper-button-next{
  color: #fff;
}
.swiper-button-prev{
  color: #fff;
}
.swiper-slide-active{
  justify-content: left;
}
.swiper-slide-active{
  justify-content: left;
}
.swiper-slide {
  .swiper-slide-active{
    justify-content: left;
  }
  position: relative;
  text-align: center;
  font-size: 15px;
  font-family: 'Open sans', sans-serif;
  background: #fff;
  justify-content: left;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.container-branch{
  button{
    color: #fff;
    width: 100%;
    z-index: 1;
  }
  #BCI{
    color: black;
  }
  #caligrafix-logo{
    width: 60%;
  }
  position: absolute;
    padding-top: -30%;
    width: 32%;
    right: 60%;
    text-align: left;
    justify-content: left;
  
}
.container-branch img{
  width: 30%;
}
.content{
  justify-content: left;
  text-align: left;
}
// .swiper-container{
//   position: relative;
//   width: 100%;
//   padding-bottom: 56.25%; 
//   height: 0;
//   background-color: black;
// }
video{
  background-color: black;
  object-fit: cover;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: 0px;
}
.swiper-container{
  margin-top: 0%;
}
.swiper-container .mySwiper{
  width: 100%; height: 100%; position: absolute;
}


.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: #fff;
}

@media(max-width: 800px){
  .swiper-container .mySwiper{
     height: 50%;
  }
  .container-branch{
    padding: 0;
    left: 15%;
    img{
      width: 50%;
    }
    #caligrafix-logo {
      width: 100%;
  }
  }
}