#about{
    width: 100px;
    height: 100px;
}
#container-about{
    scroll-behavior: smooth;
    margin-top: 300%;
}
.container-about{
    font-family: 'Dosis', sans-serif;
    font-size: 15px;
    color: white;
    z-index: 2;
    position: relative;
    text-align: center;
    overflow: hidden;
    .img-desktop{
        display: none;
    }
    img{
        width: 100%;
        height: 100%;
    }
    hr{
        width: 80%;
        border: none;
        height: 1px;
        margin-bottom: 5%;
    /* Set the hr color */
        color: #fff; /* old IE */
        background-color: #fff; /* Modern Browsers */
        
    }
    .container-p{
        h1{
            display: flow-root;
            color: #fff;
            /* margin-left: 20%; */
            border: 1px #fff solid;
            padding: 3%;
            font-size: 15pt;
            text-align: left;  
            border-left: 0;
            border-right: 0;  

        }
        justify-content: center;
        
        p{
            font-size: 20px;
            font-family: 'Open sans', sans-serif;
            padding: 20px;
            font-weight: 100;
        }
    }
    .flex-about{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .icon-about{
            width: 20%;
            height: 10%;
        }
        .hr{
            width: 80%;
        border: none;
        height: 1px;
        margin-bottom: 5%;
    /* Set the hr color */
        color: #fff; /* old IE */
        background-color: #fff; /* Modern Browsers */
        border:         none;
       
        height: 61px;
        width:          0.5px;
        }
        padding: 10%;
    }
    .container_p{
        position: relative;
        margin-top: -20%;
        background-color: rgba(0, 0, 0, 0.7);
        p{
            text-align: justify;
            font-size: 15px;
            font-family: 'Open sans', sans-serif;
            
        padding: 3%;
        
    
        }
    }
    
}

@media (min-width: 250px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 320px) {
    #container-about{
        margin-top: 30%;
    }
}
@media (min-width: 375px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 380px) {
    #container-about{
        margin-top: 50%;
    }
}
@media (min-width: 425px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 430px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 500px) {
    #container-about{
        margin-top: 0%;
    }
    .container-about{ 
        .flex-about{
            .hr{
            height: 100px;
            margin: 0px 30px;
            }
            p{
                
                font-family: 'Open sans', sans-serif;
            }
        }
    }
}
@media (min-width: 550px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 600px) {
    #container-about{
        margin-top: 0%;
    }
}

@media (min-width: 700px) {
    #container-about{
        margin-top: 0%;
    }
    .container-about{ 
        .flex-about{
            
        }
        p{
            
            font-family: 'Open sans', sans-serif;
            justify-content: center;
            text-align: center;
        }
    }
}
@media (min-width: 800px)and(min-height: 1000px) {
    #container-about{
        margin-top: 0%;
    }
}
@media (min-width: 1024px) {
    #container-about{
        margin-top: 10%;
    }
}
@media (min-width: 1100px) {
    #container-about{
        margin-top: 10%;
    }
}
@media (min-width: 1300px) {
    #container-about{
        margin-top: 10%;
    }
}
@media (max-width: 430px) {
    .container-about .container_p p{
        padding-left: 6%;
        padding-right: 6%;
    }
}
@media (max-width:1024px) {

}
@media (min-width:1024px) {
    .container-about{
        text-align: center;
        justify-content: center;
        h1{
            display: none;
        }
        .img-phone{
            display: none;
        }
        .img-desktop{
            display: block;
            /* width: 80%; */
            object-fit: cover;
            height: 100%;
            /* margin-left: 10%; */
            margin-top: 5%;

        }
        
        .container-p{
            
            // display: flex;
            // flex-direction: row;
            h1{
                display: flow-root;
                color: #fff;
                /* margin-left: 20%; */
                border: 1px #fff solid;
                padding: 3%;
                border-left: 0;
                border-right: 0;
                


            }
            justify-content: center;
            
        }
        .flex-about{
            .icon-about{
                width: 10%;
                height: 10%;
            }
            
        }
        p{
            font-size: 20px;
        }
    }
}
