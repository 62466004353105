button{
    background-color: transparent;
    border: #000000;
}
.hamburger-react{
    color: #FFFF;
}
@media (min-width: 768px) {
    .hamburger-react{
        display: none;
    }
}
