
#contract{
    width: 100px;
    height: 100px;
}
.container-contract{
    color: white;
    margin-top: 50%;
    font-size: 8pt;
    color: white;
    position: relative;
    text-align: center;
    justify-content: center;
    h1{
      display: flow-root;
      font-family: 'Dosis', sans-serif;
                color: #fff;
                /* margin-left: 20%; */
                border: 1px #fff solid;
                padding: 3%;
                text-align: left;
                border-left: 0;
                border-right: 0;
    }
    hr{
        width: 100px;
        border: none;
        height: 1px;
        
    /* Set the hr color */
        color: #333; /* old IE */
        background-color: #333; /* Modern Browsers */
    }
    h2{
        font-weight: 14pt;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
    }
    
      
    form input{
        width: 80%;
        padding: 2.5%;
        margin-top: 2%;
        margin-bottom: 3%;
        backdrop-filter: blur(1px);
        border: 0;
        color: #FFFFFF;
        background-color: #2D2D2D;
        font-family: 'Open Sans', sans-serif;    
        font-size: 15px;
      }
      form select{
        width: 80%;
        padding: 2.5%;
        color: #FFFFFF;
        margin-top: 2%;
        margin-bottom: 3%;
        backdrop-filter: blur(1px);
        border: 0;
        background-color: #2D2D2D;
        font-family: 'Open Sans', sans-serif;    
        font-size: 15px;
      
      }
      option{
        color: black;
      }
      
      form textarea{
        width: 80%;
        height: 100px;
        padding: 1.5%;
        color: #FFFFFF;
        margin-top: 2%;
        margin-bottom: 3%;
        backdrop-filter: blur(1px);
        border: 0;
        background-color: #2D2D2D;
        font-family: 'Open Sans', sans-serif;    
        font-size: 15px;
      
      }
      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFF;
        opacity: 1; /* Firefox */
      }
      form button{
        background-color: #2D2D2D ;
        color: #FFFFFF;
        height: 57px;
        padding: 15px;
        font-family: 'Dosis', sans-serif;
        width: 83%;
        cursor: pointer;
        font-size: 15px;
      }
      
      option{
        color: #FFFFFF;
      }
      .footer-mobile{
        display: none;
      }
      .footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10%;
        max-height: 100%;
        .nav{
          display: flex;
          flex-direction: column;
          a{
            text-decoration: none;
          }
          h1{
            border: 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            width: 160px;
            padding: 5%;
            text-align: left;
          }
        }
        .logo{
          width: 20%;
          height: 20px;
          margin-left: 25%;
        }
         .social-media{
          display: inline-block;
          flex-direction: row;
          img{
              cursor: pointer;
              z-index: 1;
          }
          position: absolute;
          width: 50px;
          display: inline-block;
          right: 0;
          left: 85%;
               
      }
      
       .social{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          opacity: 1;
      }
      }
      .copy{
        border: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        
        justify-content: center;
        text-align: center;
        margin-top: 30px;
      }
    }

      




@media(min-width: 1024px){
    
    .form-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 5%;
      }
    form .separate1{
        margin-left: 10%;
        
    }
    form .separate2{
      grid-row: 1 / 2;
      grid-column: 2 / 3;
      width: 90%;
      position: inherit;
    }
    .container-contract form textarea{
        height: 73%;
    }
}
@media(max-width: 1024px){
  .container-contract{
    margin-top: 50%;
  }
}

@media(max-width: 1000px){
  .container-contract{
    margin-top: 70%;
  }
}

@media(max-width: 750px){
  .container-contract{
    margin-top: 90%;
  }
}

@media(max-width: 560px){
  .container-contract{
    margin-top: 100%;
  }
}
@media(max-width: 500px){
  .container-contract{
    margin-top: 50%;
    
  }
  
    
  
}
@media(max-width: 430px){
  .container-contract{
    margin-top: 60%;
  }
}
@media(max-width: 360px){
  .container-contract{
    margin-top: 70%;
  }
}
@media(max-width: 310px){
  .container-contract{
    margin-top: 80%;
  }
}
@media(max-width: 270px){
  .container-contract{
    margin-top: 90%;
  }
}
@media(max-width: 240px){
  .container-contract{
    margin-top: 220%;
  }
}

@media(min-height: 660px){
  .container-contract{
    margin-top: 70%;
  }
}
@media(min-height: 730px){
  .container-contract{
    margin-top: 85%;
  }
}
@media(min-height: 775px){
  .container-contract{
    margin-top: 85%;
  }
}
@media(min-height: 850px){
  .container-contract{
    margin-top: 92%;
  }
}

@media(min-height: 1024px){
  .container-contract{
    margin-top: 70%;
  }
}
@media(min-height: 1130px){
  .container-contract{
    margin-top: 140%;
  }
}
@media(min-width: 1000px) and (min-height: 700px){
  .container-contract{
    margin-top: 70%;
  }
}
@media(min-width: 1200px) and (min-height: 800px){
  .container-contract{
    margin-top: 60%;
  }
}

@media(max-width: 1024px){
  .container-contract{
    .footer{
      .logo{
        width: 27%;
        height: 20px;
        margin-left: 10%;
      }
    }
  }
}
@media(max-width: 768px){
  .container-contract{
    .footer{
      display: none;
    }
    .footer-mobile{
      display: block;
      .logo{
        width: 20%;
        height: 20px;
        margin-top: 15%;
      }
      .nav{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        justify-content: center;
        margin-top: 10%;
        a{
          text-decoration: none;
        }
        #work{
          margin-left: 70%;
          width: 130px;
          text-decoration: none;
        }
        h1{
          border: 0;
          font-family: 'Open Sans', sans-serif;
          font-size: 13px;
          
          
          text-align: center;
        }
      }
      .social-media-mobile{
        display: inline;
        height: 200px;
        img{
          cursor: pointer;
          z-index: 1;
      }

      width: 50px;
     
      justify-content: center;
      .social{
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          opacity: 1;
          width: 60px;
          height: 60px;
          margin-top: 20px;
      }
      }
      
    }
    
  }
  
}
@media(max-width: 600px){
  .container-contract{
    .footer-mobile{
      .logo{
        width: 32%;
        height: 15px;
      }
  }
}
}
@media(min-width: 1024px){
  .container-contract{
    .footer{
      .logo{
        width: 20%;
        height: 20px;
        margin-left: 20%;
      }
    }
    
  }
  
}
@media(min-width: 1200px){
  .container-contract{
    .footer{
      .logo{
        width: 20%;
        height: 20px;
        margin-left: 25%;
      }
    }
    
  }
  
}
@media(min-width: 1300px){
  .container-contract{
    margin-top:50%;
  }
}
@media(min-width: 1400px){
  .container-contract{
    .footer{
      .logo{
        width: 25%;
        height: 30px;
        margin-left: 25%;
      }
    }
    
  }
  
}