#works{
    width: 100px;
    height: 100px;
}
.carousel-works{
    color: #fff;
    text-align: center;
    font-family: 'Dosis', sans-serif;
    font-size: 8pt;
    text-align: left;
    .header-carousel{
        .header{
            h1{
                display: flow-root;
                color: #fff;
                /* margin-left: 20%; */
                border: 1px #fff solid;
                padding: 3%;
                border-left: 0;
                border-right: 0;
                border-left: 0;
                border-right: 0;
            }
            hr{
                width: 80%;
                margin-bottom: 5%;
        border: none;
        height: 1px;
    /* Set the hr color */
        color: #333; /* old IE */
        background-color: #333; /* Modern Browsers */
            }
        }
    }
    @media(min-width: 1024px){
        hr{
            display: none;
        }
        .cards-videos{
            display: none;
        }
        .swiper {
            
            width: 100%;
            height: 100%;
          }
          .swiper-button-next{
            color: #fff;
        }
        .swiper-button-prev{
            color: #fff;
        }
        video{
            width: 100%;
            height: 80%;
        } 
          .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #000;
          
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
          }
          
          
    } 
    

}

@media(max-width: 1024px){
    .carousel-works{
        
    }
    .mySwiper2{
        display: none;
    }
    #myswiper2{
        display: none;
    }
    .cards-videos{
        display: contents;
    }
}
@media(max-height: 500px){
    .carousel-works{
    }
    
}


.swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-button-next{
    color: #fff;
  }
  .swiper-button-prev{
    color: #fff;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  // .swiper-container{
  //   position: relative;
  //   width: 100%;
  //   padding-bottom: 56.25%; 
  //   height: 0;
  //   background-color: black;
  // }
  video{
    background-color: black;
    object-fit: cover;
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 0px;
  }
  .swiper-container{
    margin-top: 0%;
  }
  .swiper-container .mySwiper{
    width: 100%; height: 50%; position: absolute;
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #fff;
  }

  