// div {
//     position: relative;
//   }
  
  
  .container.gif{
    display: relative;
    top: 0;
    left: 0;
  }
  .inner-image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    
  }
  .small-image {
    display: none;
  }

  @media (max-width: 500px) {
    .inner-image{
      display: none;
    }
    .small-image{
      display: block;
      max-width: 100%;
      height: 300px;
      object-fit: cover;
    }
}
@media (min-width: 800px) {
  
  
}