$openModal: false;
$size: 100px;
.grid{
  display: flex;
  flex-direction: column;
  
}

.menu{
  position: absolute;
}
.services-four{
  z-index: 1;
}